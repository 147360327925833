


































































































import Vue from "vue";
import Calendar from "@/components/Calendar.vue";
import Checklist from "@/components/Checklist.vue";
import Notes from "@/components/Notes.vue";
import { mapActions } from "vuex";
import { tokenValid } from "@/utils/api";
// mikäli talviohjeet tulee takasin
// import SeasonEnd from "@/components/SeasonEnd.vue";

export default Vue.extend({
  name: "Home",
  data: function () {
    return { tab: 0 };
  },
  mounted() {
    if (!tokenValid()) {
      this.$router.push({ path: "/" });
    }
    this.fetchReservations();
  },
  methods: {
    ...mapActions(["fetchReservations"]),
  },
  // components: { Calendar, Checklist, Notes, SeasonEnd },
  components: { Calendar, Checklist, Notes },
});
