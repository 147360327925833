






















































import Vue from "vue";
import { tokenValid, login } from "@/utils/api";

export default Vue.extend({
  name: "Login",
  data: function () {
    return { answer: "" };
  },
  mounted() {
    if (tokenValid()) {
      this.$router.push({ path: "/home" });
    }
  },
  methods: {
    async login() {
      const loginResp = await login(this.answer);
      console.log(loginResp);
      if (loginResp) {
        this.$router.push({ path: "/home" });
      }
    },
  },
});
