import router from "@/router";
import axios, { AxiosResponse } from "axios";
import jwtDecode from "jwt-decode";

interface LoginResp {
  success: boolean;
  token: string;
}

export interface Reservation {
  id: number;
  reserver: string;
  startDate: Date;
  endDate: Date;
  createdAt: Date;
  updatedAt: Date;
}

export interface Note {
  id: number;
  reserver: string;
  note: string;
  createdAt: Date;
  updatedAt: Date;
}

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_PREFIX,
  headers: {
    "x-token": {
      toString() {
        const token = getToken();
        if (token) {
          return token;
        }
        return "";
      },
    },
  },
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      removeToken();
      router.push("/");
    }
  }
);

export async function login(answer: string): Promise<boolean> {
  try {
    const loginResp: AxiosResponse<LoginResp> = await axiosInstance.post(
      "/login",
      { answer: answer.toLowerCase() }
    );
    if (loginResp.data.success) {
      setToken(loginResp.data.token);
      return true;
    }
    return false;
  } catch {
    return false;
  }
}

export async function getReservations(): Promise<Reservation[] | undefined> {
  try {
    const reservationResp: AxiosResponse<Reservation[]> =
      await axiosInstance.post("/reservations");
    return reservationResp.data.map((x) => ({
      ...x,
      startDate: new Date(x.startDate),
      endDate: new Date(x.endDate),
    }));
  } catch (err) {
    console.log(err);
  }
}

export async function saveReservation(
  res: Partial<Reservation>
): Promise<void> {
  try {
    await axiosInstance.post("/reservations/create", res);
  } catch (err) {
    console.log(err);
  }
}

export async function editReservation(
  id: number,
  res: Partial<Reservation>
): Promise<void> {
  try {
    await axiosInstance.post(`/reservations/${id}`, res);
  } catch (err) {
    console.log(err);
  }
}

export async function deleteReservation(id: number): Promise<void> {
  try {
    await axiosInstance.delete(`/reservations/${id}`);
  } catch (err) {
    console.log(err);
  }
}

export async function saveNote(res: Partial<Note>): Promise<void> {
  try {
    await axiosInstance.post("/notes/create", res);
  } catch (err) {
    console.log(err);
  }
}

export async function getNotes(): Promise<Note[] | undefined> {
  try {
    const noteResp: AxiosResponse<Note[]> = await axiosInstance.post("/notes");
    return noteResp.data.sort(
      (a, b) =>
        new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()
    );
  } catch (err) {
    console.log(err);
  }
}

export async function deleteNote(id: number): Promise<void> {
  try {
    await axiosInstance.delete(`/notes/${id}`);
  } catch (err) {
    console.log(err);
  }
}

export function setToken(token: string): void {
  localStorage.setItem("token", token);
}

export function removeToken(): void {
  localStorage.removeItem("token");
}

export function getToken(): string | null {
  return localStorage.getItem("token");
}

export function tokenValid(): boolean {
  try {
    const token = getToken();
    if (!token) {
      return false;
    }
    const decodedToken = jwtDecode(token);
    const expiry = new Date((decodedToken as { exp: number }).exp * 1000);
    return expiry > new Date();
  } catch (err) {
    return false;
  }
}
