









































































import Vue from "vue";
import { saveReservation, Note, saveNote } from "@/utils/api";
import { mapActions } from "vuex";
import MobileMixin from "../utils/mobile-mixin";

export default Vue.extend({
  name: "NewNote",
  data(): {
    dialog: boolean;
    writer: string;
    note: string;
  } {
    return {
      dialog: false,
      writer: "",
      note: "",
    };
  },
  mixins: [MobileMixin],
  methods: {
    ...mapActions(["fetchNotes"]),
    reset() {
      this.writer = "";
      this.note = "";
    },
    async save() {
      const res: Partial<Note> = {
        reserver: this.writer,
        note: this.note,
      };
      await saveNote(res);
      await this.fetchNotes();
      this.reset();
      this.dialog = false;
    },
  },
});
