

















































































import Vue from "vue";
import NewReservation from "@/components/NewReservation.vue";
import { mapActions, mapGetters } from "vuex";
import { deleteReservation, Reservation } from "@/utils/api";
import format from "date-fns/format";
import parse from "date-fns/parse";
import { fi } from "date-fns/locale";
import { CalendarEvent } from "vuetify";

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export default Vue.extend({
  data(): {
    d: string;
    selectedEvent: CalendarEvent;
    selectedElement: EventTarget | null;
    selectedOpen: boolean;
  } {
    return {
      d: "",
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
    };
  },
  methods: {
    ...mapActions(["fetchReservations"]),
    prev() {
      (this.$refs.calendar as any).prev();
    },
    next() {
      (this.$refs.calendar as any).next();
    },
    async deleteEvent() {
      await deleteReservation(this.selectedEvent.id);
      await this.fetchReservations();
      this.selectedOpen = false;
      this.selectedElement = null;
      this.selectedEvent = {};
    },
    async editEvent() {
      (this.$refs.newReservation as any).edit(this.selectedEvent.id);
    },
    showEvent({
      nativeEvent,
      event,
    }: {
      nativeEvent: Event;
      event: CalendarEvent;
    }): void {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
  },
  computed: {
    ...mapGetters(["getReservations"]),
    getYear(): string {
      const date = this.d
        ? parse(this.d, "yyyy-MM-dd", new Date())
        : new Date();
      return format(date, "yyyy", { locale: fi });
    },
    getMonth(): string {
      const date = this.d
        ? parse(this.d, "yyyy-MM-dd", new Date())
        : new Date();
      return capitalizeFirstLetter(format(date, "LLLL", { locale: fi }));
    },
    getEvents() {
      const reservations: Reservation[] = this.getReservations;
      return reservations.map((x) => {
        return {
          name: x.reserver,
          id: x.id,
          start: x.startDate,
          end: x.endDate,
          color: "blue",
          timed: false,
        };
      });
    },
  },
  components: {
    NewReservation,
  },
});
