export default {
  methods: {
    isMobile(): boolean {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
  },
};
