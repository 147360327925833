



















































































import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import * as _ from "lodash";
import { deleteNote, Note } from "@/utils/api";
import NewNote from "./NewNote.vue";
import format from "date-fns/format";
import { fi } from "date-fns/locale";
import isMobileMixin from "@/utils/mobile-mixin";

export default Vue.extend({
  name: "Notes",
  mixins: [isMobileMixin],
  data(): {
    currentPage: number;
  } {
    return {
      currentPage: 0,
    };
  },
  mounted() {
    this.fetchNotes();
  },
  methods: {
    ...mapActions(["fetchNotes"]),
    next() {
      this.currentPage -= 1;
    },
    prev() {
      this.currentPage += 1;
    },
    formatDate(d: string) {
      const date = new Date(d);
      return format(date, "d. MMMM yyyy", { locale: fi });
    },
    async deleteNote(id: number) {
      await deleteNote(id);
      this.fetchNotes();
    },
  },
  computed: {
    ...mapGetters(["getNotes"]),
    pages(): Note[][] {
      return _.chunk(this.getNotes, 5);
    },
    notes(): Note[] {
      console.log(this.pages.length);
      console.log(this.pages);
      console.log(this.currentPage);
      return this.pages[this.currentPage];
    },
  },
  components: {
    NewNote,
  },
});
