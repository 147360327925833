






















































































































import Vue from "vue";
import { saveReservation, Reservation, editReservation } from "@/utils/api";
import { mapActions, mapGetters } from "vuex";
import setHours from "date-fns/setHours";
import parseDate from "date-fns/parse";
import format from "date-fns/format";
import MobileMixin from "../utils/mobile-mixin";

export default Vue.extend({
  name: "NewReservation",
  data(): {
    dialog: boolean;
    reserver: string;
    editId: number | null;
    dates: string[];
    reserverRules: [(str: string) => string | boolean];
  } {
    return {
      dialog: false,
      reserver: "",
      editId: null,
      dates: [],
      reserverRules: [
        (value: string) => !!value || "Muista kertoa varaajan nimi",
      ],
    };
  },
  mixins: [MobileMixin],
  methods: {
    ...mapActions(["fetchReservations"]),
    ...mapGetters(["getReservations"]),
    reset() {
      this.editId = null;
      this.reserver = "";
      this.dates = [];
    },
    edit(id: number): void {
      const reservation = this.getReservations().find(
        (x: Reservation) => x.id === id
      );
      console.log(reservation);
      if (reservation) {
        this.dates = [
          format(reservation.startDate, "yyyy-MM-dd"),
          format(reservation.endDate, "yyyy-MM-dd"),
        ];
        this.editId = id;
        this.reserver = reservation.reserver;
        this.dialog = true;
      }
    },
    async save() {
      const startDate = setHours(
        parseDate(this.dates.sort()[0], "yyyy-MM-dd", new Date()),
        12
      );
      const endDate = setHours(
        parseDate(this.dates.sort()[1], "yyyy-MM-dd", new Date()),
        12
      );
      const res: Partial<Reservation> = {
        reserver: this.reserver,
        startDate: startDate,
        endDate: endDate,
      };
      this.editId
        ? await editReservation(this.editId, res)
        : await saveReservation(res);
      await this.fetchReservations();
      this.reset();
      this.dialog = false;
    },
  },
});
