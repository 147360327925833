import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import fi from "vuetify/src/locale/fi";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { fi },
    current: "fi",
  },
  theme: {
    themes: {
      light: {
        primary: "#607d8b",
        secondary: "#009688",
        accent: "#9c27b0",
        error: "#f44336",
        warning: "#ff9800",
        info: "#2196f3",
        success: "#4caf50",
      },
    },
  },
});
