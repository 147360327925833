import Vue from "vue";
import Vuex from "vuex";
import { getNotes, getReservations, Note, Reservation } from "../utils/api";

Vue.use(Vuex);

interface State {
  reservations: Reservation[];
  notes: Note[];
}

export default new Vuex.Store({
  state: {
    reservations: [],
    notes: [],
  },
  getters: {
    getReservations: (state: State) => state.reservations,
    getReservationById: (state: State) => (id: number) =>
      state.reservations.find((x) => x.id === id),
    getNotes: (state: State) => state.notes,
  },
  mutations: {
    setReservations(state: State, payload: Reservation[]) {
      state.reservations = [...payload];
    },
    setNotes(state: State, payload: Note[]) {
      state.notes = [...payload];
    },
  },
  actions: {
    async fetchReservations({ commit }) {
      const reservations = await getReservations();
      commit("setReservations", reservations);
    },
    async fetchNotes({ commit }) {
      const notes = await getNotes();
      commit("setNotes", notes);
    },
  },
  modules: {},
});
